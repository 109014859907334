


























































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  AdminRegisterMember,
  RegisterMember,
  EGroupRegisterType
} from '@/models'
import GroupMemberService from '@/services/GroupMemberService'

@Component
export default class GroupRegister extends Vue {
  private newMember: AdminRegisterMember = new AdminRegisterMember()
  private passwordConfirm: string = ''
  private registerType: EGroupRegisterType = Number(this.$route.query.type)
  private groupLink: string = this.$route.query.group as string
  private userUniqueUrl: string = this.$route.query.url as string
  private eGroupRegisterType = EGroupRegisterType
  private passwordMatch: boolean = true
  private modalMess: string = ''
  private email: string = ''

  created() {
    this.init()
  }

  /**
   * Check confirm password
   */
  @Watch('passwordConfirm')
  checkConfirmPassword() {
    if (this.passwordConfirm.localeCompare(this.newMember.password) === 0) {
      this.passwordMatch = true
      return
    }
    this.passwordMatch = false
  }

  /**
   * init
   */
  async init() {
    switch (this.registerType) {
      case EGroupRegisterType.ADMIN_REGISTER:
        if (!this.$route.params.groupId) {
          this.$router.push({ name: 'home' })
        }
        break
      case EGroupRegisterType.USER_REGISTER_INVITE:
      case EGroupRegisterType.USER_REGISTER_REQUEST_JOIN:
        const checkUrl = await this.handleUserUniqueUrl()

        //this case for check token true and use had logined
        if (this.$store.state.userInfo.user.info.id) {
          this.$router.push({ name: 'home' })
          break
        }

        //this case for token not expired but user have not login yet
        if (!checkUrl) {
          this.$router.push({
            name: 'login',
            query: {
              redirect: this.$route.fullPath
            }
          })
        }
        break
      case EGroupRegisterType.USER_TEPM_REGISTER:
        if (!this.groupLink) {
          this.$router.push({ name: 'home' })
        }
        break
      default:
        break
    }
  }

  /**
   * check user token
   */
  async handleUserUniqueUrl() {
    if (!this.userUniqueUrl) return false
    let check = false
    if (this.registerType === EGroupRegisterType.USER_REGISTER_INVITE) {
      check = await GroupMemberService.checkInvitedUserUniqueUrl(
        this.userUniqueUrl
      )
        .then(res => {
          if (res.data.is_valid && !res.data.is_temp_user) {
            this.$router.push({ name: 'login' })
            return true
          }

          return res.status === 200
        })
        .catch(err => {
          //this case for token expired
          if (err.response.status === 404) {
            this.$router.push({ name: 'token-expired' })
            return true
          }
          return false
        })
    } else if (
      this.registerType === EGroupRegisterType.USER_REGISTER_REQUEST_JOIN
    ) {
      check = await GroupMemberService.checkJoinRequestUserUniqueUrl(
        this.userUniqueUrl
      )
        .then(res => {
          return res.status === 200
        })
        .catch(err => {
          //this case for token expired
          if (err.response.status === 404) {
            this.$router.push({ name: 'token-expired' })
            return true
          }
          return false
        })
    }
    return check
  }

  /**
   * Submit register form
   */
  submitRegister() {
    this.newMember.group_id = this.$route.params.groupId
    switch (this.registerType) {
      case EGroupRegisterType.ADMIN_REGISTER:
        this.$blockui.show()
        GroupMemberService.registerAsMemberByAdmin(this.newMember)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.modalMess = this.$t('common.message.registered') as string
              this.$bvModal.show('modal-success')
            }
          })
          .catch(err => {
            if (
              err.response.status === 422 &&
              err.response.data.errors['employee_number']
            ) {
              this.modalMess = this.$t(
                'common.message.eid_already_used'
              ) as string
              this.$bvModal.show('modal-error')
            } else if (
              err.response.status === 404 &&
              err.response.data.message == 'group_not_found_or_stopped'
            ) {
              this.modalMess = this.$t(
                'common.message.group_not_found_or_deleted'
              ) as string
              this.$bvModal.show('modal-error')
            } else {
              this.modalMess = this.$t('common.message.register_fail') as string
              this.$bvModal.show('modal-error')
            }
          })
          .finally(() => this.$blockui.hide())
        break
      case EGroupRegisterType.USER_REGISTER_INVITE:
      case EGroupRegisterType.USER_REGISTER_REQUEST_JOIN:
        if (this.passwordMatch) {
          let newMem = new RegisterMember(this.newMember)
          newMem.type = this.registerType
          newMem.token = this.userUniqueUrl
          this.$blockui.show()
          GroupMemberService.registerAsMember(newMem)
            .then(res => {
              if (res.status === 200) {
                this.modalMess = this.$t('common.message.registered') as string
                this.$bvModal.show('modal-success')
              }
            })
            .catch(err => {
              if (
                err.response.status === 400 &&
                err.status.data.message == 'update_from_invite_link_fail'
              ) {
                this.modalMess = this.$t(
                  'common.message.update_from_invite_link_fail'
                ) as string
              } else if (
                err.response.status === 404 &&
                err.response.data.message == 'group_not_found_or_stopped'
              ) {
                this.modalMess = this.$t(
                  'common.message.group_not_found_or_deleted'
                ) as string
              } else {
                this.modalMess = this.$t(
                  'common.message.register_fail'
                ) as string
              }
              this.$bvModal.show('modal-error')
            })
            .finally(() => this.$blockui.hide())
        }
        break
      case EGroupRegisterType.USER_TEPM_REGISTER:
        this.$blockui.show()
        GroupMemberService.registerAsTempMember({
          email: this.email,
          group_link: this.groupLink,
          url_bridges: window.location.origin,
          link_url: `${window.location.origin}/register?type=2&url=`
        })
          .then(res => {
            if (res.status === 201) {
              this.modalMess = this.$t('common.message.check_email') as string
              this.$bvModal.show('modal-success')
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              this.modalMess = this.$t(
                'common.message.user_already_actived'
              ) as string
              this.$bvModal.show('modal-error')
            } else if (err.response.status === 422) {
              this.modalMess = this.$t(
                'common.message.mail_already_used'
              ) as string
              this.$bvModal.show('modal-error')
            } else if (err.response.status === 404) {
              this.modalMess = this.$t(
                'common.message.group_not_found_or_deleted'
              ) as string
              this.$bvModal.show('modal-error')
            } else {
              this.modalMess = this.$t('common.message.register_fail') as string
              this.$bvModal.show('modal-error')
            }
          })
          .finally(() => this.$blockui.hide())
        break
      default:
        break
    }
  }

  modalSuccessClose() {
    if (this.registerType === EGroupRegisterType.ADMIN_REGISTER) {
      window.close()
    } else {
      this.$router.push({ name: 'login' })
    }
  }
}
